<template>
  <v-card flat class="pa-4 my-6 mx-9 rounded-lg" color="#f5f7f7">
    <div id="bar-chart"></div>
  </v-card>
</template>

<script>
import * as d3 from "d3";

export default {
  props: ["details"],

  mounted() {
    // destructure data
    const { width: w, height: h, padding, data, hoverColor } = this.details;

    // setup container
    const svg = d3
      .select("#bar-chart")
      .append("svg")
      .attr("width", w)
      .attr("height", h);

    const margin = { top: 20, bottom: 40, left: 50, right: 0 };
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // x-scale
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.year))
      .range([0, width])
      .padding(padding);

    // x-axis
    g.append("g")
      .classed("xAxis", true)
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    // y-scale
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, 0]);

    // y-axis
    const yAxis = d3
      .axisLeft(yScale)
      .tickFormat(d3.format("$,.0f"))
      .ticks(5)
      .tickSize(-width);

    g.append("g").classed("yAxis", true).call(yAxis);

    // bars
    const bars = g
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.year))
      .attr("y", (d) => yScale(d.value))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => height - yScale(d.value))
      .style("fill", (d) => d.fill);

    // bar mouseover
    bars
      .on("mouseover", function (e, d) {
        d3.select(this).style("fill", hoverColor);
        d3.select("body").style("cursor", "pointer");
      })
      .on("mouseout", function (e, d) {
        d3.select(this).style("fill", d.fill);
        d3.select("body").style("cursor", "default");
      });

    // annotation top
    g.selectAll(".annotation")
      .data(data)
      .enter()
      .filter((d) => d.labelTop)
      .append("text")
      .classed("label-top", true)
      .attr("x", (d) => xScale(d.year) + 0.5 * xScale.bandwidth())
      .attr("y", (d) => yScale(d.value) - 10)
      .style("text-anchor", "middle")
      .text((d) => d.labelTop);

    // annotation bottom
    g.selectAll(".annotation")
      .data(data)
      .enter()
      .filter((d) => d.labelBottom)
      .append("text")
      .classed("label-bottom", true)
      .attr("x", (d) => xScale(d.year) + 0.5 * xScale.bandwidth())
      .attr("y", () => height + 35)
      .style("text-anchor", "middle")
      .text((d) => d.labelBottom);

    // adjust axis styling
    g.select(".xAxis").selectAll(".domain").remove();
    g.select(".yAxis").selectAll("line").style("stroke", "rgba(0,0,0,0.1)");
    g.select(".yAxis").selectAll(".domain").remove();

    // adjust fonts
    g.selectAll("text").style("font-size", "15px").style("font", "13px roboto");
    g.select(".xAxis").selectAll("text").style("font-weight", 900);
    g.selectAll(".label-bottom").style("font-weight", 400);
    g.selectAll(".label-top").style("font-weight", 900);
  },
};
</script>

<style lang="scss" scoped>
</style>